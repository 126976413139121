<template>
  <div class="modal menu-modal">
    <div class="modal-content">
      <div class="x-modal-header">
        <close-icon @click="close"></close-icon>
      </div>
      <h3>Account Management</h3>
      <div v-if="querent_subscribed">
        <p>Subscribed expiration {{ formattedSubscriptionExpiration }}</p>
      </div>
      <form @submit.prevent="submitForm">
        <input v-model="formData.querent_id" type="hidden" />
        <div class="form-section">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="formData.name" required />
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="formData.email" required />
        </div>
        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </div>
</template>


<script>
import { format } from 'date-fns';
import axios from 'axios';
import CloseIcon from '@/components/ui/CloseIcon.vue';
import { store } from '@/store';
import { API_URL } from '@/constants.js';

export default {
  components: {
    CloseIcon,
  },

  name: 'AccountManagement',
  props: {
    querent_name: String,
    querent_subscribed:Boolean
  },
  data() {
    return {
      querentName: this.querent_name,
      formData: {
        querent_id: store.querent_id,
        name: store.querent_name || '',
        email: store.querent_email || '',
        querent_subscription_expiration: store.querent_subscription_expiration || ''
      },
      store,
    };
  },
  computed: {
  formattedSubscriptionExpiration() {
    return format(new Date(this.store.querent_subscription_expiration), 'MM-dd-yyyy');
  }
},
  methods: {
    close() {
      this.$emit('close');
    },
    async submitForm() {
      try {
        await axios.post(API_URL + '/querent/manage-account/', this.formData);
        this.$emit('updateQuerentName', this.formData.name);
        this.$notify({
            text: 'Account Update Successful',
            type: 'success',
          })
        this.store.querent_name = this.formData.name;
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  text-align: left; /* Ensures all text is left justified */
}

.x-modal-header {
  display: flex;
  justify-content: flex-end;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Left justifies all form elements */
}

.form-section {
  margin-bottom: 20px;
}

label {
  margin: 10px 0 5px;
  font-weight: bold;
}

input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  background-color: #28a745;
  color: white;
}

button:hover {
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
  .modal-content {
    padding: 10px;
  }

  input {
    padding: 6px;
  }

  .submit-button {
    padding: 8px;
  }
}

</style>
