export const mockReading = {
    "data": {
        "user_message": "Tell me about my future",
        "selected_cards": [
            {
                "name": "The Tower",
                "reversed": false,
                "description": "Sudden change, upheaval, chaos, revelation",
                "reverse_description": "Personal transformation, fear of change, averting disaster",
                "image_path": "https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg"
            },
            {
                "name": "Death",
                "reversed": false,
                "description": "Endings, change, transformation, transition",
                "reverse_description": "Resistance to change, personal transformation, inner purging",
                "image_path": "https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg"
            },
            {
                "name": "Ten of Swords",
                "reversed": false,
                "description": "Betrayal, backstabbing, end of a cycle, loss, crisis",
                "reverse_description": "Recovery, regeneration, fear of ruin, inevitable end",
                "image_path": "https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg"
            },
            {
                "name": "Four of Pentacles",
                "reversed": false,
                "description": "Conservation, security, frugality, saving money",
                "reverse_description": "Greed, materialism, self-protection, stinginess",
                "image_path": "https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg"
            }
        ],
        "reading": "The synthesis of the tarot cards—The Tower, Death, Ten of Swords, and Four of Pentacles—in relation to your query 'Tell me about my future' paints a narrative of profound transformation and evolution. Your recent past has been marked by disruptive changes with The Tower, having encountered an upheaval that tested the foundations of your life. The presence of Death in the present position underlines that you are in the midst of a significant transition, shedding the old self and old ways to allow for rebirth and renewal. Building on these themes, the Ten of Swords in the future position suggests that while you may still encounter difficulties or feelings of betrayal, these experiences are signaling the end of a deeply challenging phase, after which recovery and new beginnings can emerge. The Four of Pentacles gives counsel on your current stance: to reflect on your grasp over material and emotional resources during this transformative period. It encourages a balance between security and adaptability, hinting that the way you handle your resources and openness to change will play a crucial role in navigating the shifts ahead. Collectively, these cards signal a time of significant inner and outer change, where the challenges of the past and future serve as catalysts for growth and the emergence of a new phase of life.",
        "image_url": "https://mysticaida.blob.core.windows.net/public-dev/reading_session_image/c55434af-1ee8-4e4b-9f70-84a7b865e3de.jpg",
        "card_1": "The Tower in the past position suggests that you've gone through a significant upheaval or a sudden change that shook the very foundation of your life. This might have been a challenging time, where structures you once thought were stable were revealed to be otherwise. The lesson from The Tower is that while such events can be traumatic, they also offer the opportunity for growth, liberation from old confines, and the chance to rebuild on clearer, more honest grounds.",
        "card_2": "Death in the present position indicates that you are currently experiencing or are on the cusp of a major transformation. This period of transition is about shedding the old to make room for the new. Often mistakenly feared, the Death card heralds a natural process of letting go, which is necessary for growth and renewal. Embrace the changes that are happening, as they will lead to a reinvigorated way of life.",
        "card_3": "Ten of Swords in the future position can be an intense and challenging card to see because it signifies betrayal, loss, or a deep wound. However, it also marks the end of a cycle of pain, suggesting that whatever hardships you face will soon come to a close. The Ten of Swords encourages you to accept the bottom as a place from which you can finally rise. Keep in mind, the darkest hour is just before dawn, and the future will offer the chance to move beyond current difficulties.",
        "card_4": "Four of Pentacles as advice invites you to examine your relationship with control and material security. It may be warning you against holding on too tightly to your resources or resisting necessary change out of fear. The card advises you to loosen your grip and consider what you might be sacrificing in the name of security. Balancing financial stability with generosity and openness to change will be important as you move through your current transitions.",
        "follow_up_question": "Considering the transformational journey you are on, what aspects of your life are you ready to release, and where can you foster greater openness and adaptability to embrace the new paths unfolding before you?"
    },
    "status": 200,
    "statusText": "OK",
    "headers": {
        "content-length": "4775",
        "content-type": "application/json"
    },
    "config": {
        "transitional": {
            "silentJSONParsing": true,
            "forcedJSONParsing": true,
            "clarifyTimeoutError": false
        },
        "adapter": [
            "xhr",
            "http"
        ],
        "transformRequest": [
            null
        ],
        "transformResponse": [
            null
        ],
        "timeout": 0,
        "xsrfCookieName": "XSRF-TOKEN",
        "xsrfHeaderName": "X-XSRF-TOKEN",
        "maxContentLength": -1,
        "maxBodyLength": -1,
        "env": {},
        "headers": {
            "Accept": "application/json, text/plain, */*",
            "Content-Type": "application/json"
        },
        "method": "post",
        "url": "http://localhost:8000/reading/querent/",
        "data": "{\"message\":\"Tell me about my future\",\"selected_cards\":[{\"name\":\"The Tower\",\"reversed\":false,\"description\":\"Sudden change, upheaval, chaos, revelation\",\"reverse_description\":\"Personal transformation, fear of change, averting disaster\",\"image_path\":\"https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg\"},{\"name\":\"Death\",\"reversed\":false,\"description\":\"Endings, change, transformation, transition\",\"reverse_description\":\"Resistance to change, personal transformation, inner purging\",\"image_path\":\"https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg\"},{\"name\":\"Ten of Swords\",\"reversed\":false,\"description\":\"Betrayal, backstabbing, end of a cycle, loss, crisis\",\"reverse_description\":\"Recovery, regeneration, fear of ruin, inevitable end\",\"image_path\":\"https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg\"},{\"name\":\"Four of Pentacles\",\"reversed\":false,\"description\":\"Conservation, security, frugality, saving money\",\"reverse_description\":\"Greed, materialism, self-protection, stinginess\",\"image_path\":\"https://mysticaida.blob.core.windows.net/public/reader_decks/1_.jpg\"}],\"reading_session_id\":\"67c3c59d-3335-4c86-a1d3-118811944a8e\",\"querent_id\":\"b14038fd-6b9c-47b9-aa0a-d4a9121b348f\"}"
    },
    "request": {}
}