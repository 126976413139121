<script setup>
import { ref, onMounted } from 'vue';
import { useNotification } from "@kyvg/vue3-notification";
import { resetPassword } from '../api-service'
const { notify } = useNotification()


const password = ref('');
const confirmPassword = ref('');
const token = ref('');


onMounted(() => {
    const queryParams = new URLSearchParams(window.location.search);
    token.value = queryParams.get('token');
});

const submitForm = async () => {
    if (password.value !== confirmPassword.value) {
        notify({
            title: "Invalid Password",
            text: "Password must match confirm password.",
            type: "error",
        });
        return;
    }
    if (validatePassword(password.value)) {
        console.log('Password is valid.');
        const response = await resetPassword(token.value, password.value)
        console.log('reset response', response);
        if (response?.status === 'fail') {
            notify({
                title: "Fail",
                text: response?.message,
                type: "error",
            });
            return;
        }
        else {
            notify({
                title: "Success",
                text: "Password reset success",
                type: "success",
            });
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        }

        // You can add your post request logic here
    } else {
        notify({
            title: "Invalid Password",
            text: "The password must be at least 6 characters long and include either an uppercase letter or a special character.",
            type: "error",
        });
        console.error('Password does not meet the requirements.');
    }
};

const validatePassword = (password) => {
    return password.length >= 6 && (/[A-Z]/.test(password) || /[!@#$%^&*(),.?":{}|<>]/.test(password));
};
</script>

<template>
    <div class="container">
        <div class="form-box">
            <h2>Reset Password</h2>
            <form @submit.prevent="submitForm">
                <div class="input-group">
                    <label for="password">New Password:</label>
                    <input type="password" id="password" v-model="password" />
                </div>
                <div class="input-group">
                    <label for="confirmPassword">Confirm Password:</label>
                    <input type="password" id="confirmPassword" v-model="confirmPassword" />
                </div>
                <button type="submit" class="submit-button">Reset Password</button>
            </form>
        </div>
    </div>
</template>


<style>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.form-box {
    background: white;
    padding: 80px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.input-group {
    margin-bottom: 20px;
}

input[type="password"] {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #663399;;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add this line */
}

.submit-button:hover {
    background-color: #8c66b2;
}
</style>
