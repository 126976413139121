<template>
  <div class="top-right-menu" v-if="!querentId">
    <button @click="showLoginModal">Login</button>
  </div>

  <div class="top-right-menu" v-if="querentId">
    <!-- Hamburger Icon -->
    <button @click="toggleMenu" class="hamburger-icon">
      <i class="fas fa-bars"></i>
    </button>

    <!-- Dropdown Menu -->

    <ul v-if="showMenu" class="nav-dropdown">
      <li @click="showAccountModal">
        <i class="fa fa-user"></i> Account
      </li>
      <li @click="showReadingHistoryModal">
        <i class="fa fa-book"></i> Reading History
      </li>
<!--      <li @click="showHoroscopeSettingsModal">-->
<!--        <i class="fa fa-star"></i> Horoscope Settings-->
<!--      </li>-->
      <li @click="signOut">
        <i class="fa fa-sign-out"></i> Sign Out
      </li>

      <!-- <li @click="show_merch_shop_modal">
        <i class="fa fa-shopping-cart"></i> Merch Shop
      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    querentId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  emits: ['toggleMenu', 'showReadingHistoryModal', 'signOut', 'showHoroscopeSettingsModal', 'update:showLogin', 'showAccountModal'],
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
      this.showMenu = !this.showMenu;
    },
    showReadingHistoryModal() {
      this.$emit('showReadingHistoryModal');
      this.showMenu = !this.showMenu;
    },
    showLoginModal() {
      this.$emit('update:showLogin', true);
    },
    showHoroscopeSettingsModal() {
      this.$emit('showHoroscopeSettingsModal');
      this.showMenu = !this.showMenu;
    },
    signOut() {
      this.$emit('signOut');
    },
    showAccountModal() {
      this.$emit('showAccountModal');
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
