<script setup>
import { ref, computed } from 'vue'
import TarotReadingPage from '../pages/TarotReadingPage.vue';
import NotFound from './NotFound.vue';
import ResetPasswordPage from '../pages/ResetPasswordPage.vue';
import SharePage from './SharePage.vue'

const routes = {
  '/': TarotReadingPage,
  '/reset-password': ResetPasswordPage,
  '/share': SharePage,
  // no need to explicitly define '/not-found/' here
};

const currentPath = ref(window.location.pathname);

window.addEventListener('popstate', () => {
  currentPath.value = window.location.pathname;
});

const currentView = computed(() => {
  return routes[currentPath.value] || NotFound;
});
</script>

<template>
  <component :is="currentView" />
</template>