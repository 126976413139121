<template>
  <div class="top-left-menu" v-if="querentId && !checkingSession">
    <ul class="emerald-list">
      <li>
        <a @click.prevent="showEmeraldModal" title="Number of emeralds balance" class="emerald-color">
          <i class="fas fa-gem" title="Emerald Balance"></i> {{ querentEmeraldBalance }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    querentId: {
      type: [String, Number],
      required: true,
    },
    querentEmeraldBalance: {
      type: Number,
      required: true,
    },
    checkingSession: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    showEmeraldModal() {
      this.$emit('showEmeraldModal');
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
