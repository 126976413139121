<template>
  <div>
    <audio ref="emeraldAudioPlayer" :src="emeraldSoundFile"></audio>
    <audio ref="selectAudioPlayer" :src="selectSoundFile"></audio>
    <audio ref="selectCardAudioPlayer" :src="selectCardFile"></audio>
  </div>
</template>

<script>
import emeraldSoundFile from '@/assets/emerald_sound.mp3';
import selectSoundFile from '@/assets/select_sound.mp3';
import selectCardFile from '@/assets/select_card.mp3';

export default {
  data() {
    return {
      emeraldSoundFile: emeraldSoundFile,
      selectSoundFile: selectSoundFile,
      selectCardFile: selectCardFile,
    };
  },
  methods: {
    playEmeraldSound() {
      this.$refs.emeraldAudioPlayer.play();
    },
    playSelectSound() {
      this.$refs.selectAudioPlayer.play();
    },
    playSelectCardSound() {
      this.$refs.selectCardAudioPlayer.play();
    }
  }
};
</script>
