<template>
  <div>
    <!-- Container for Revealed Cards START -->
    <div class="revealed-cards-container" v-show="showRevealedCards">
      <div class="card" v-for="card in revealedCards" :key="card.name">
        <div :style="card.reversed ? { transform: 'rotate(180deg)' } : {}" class="card-label">
          <b>{{ card.name }}</b>
        </div>
        <div class="card-image">
          <img :src="card.image_path" :alt="card.name" :class="['tarot-card', { 'reversed-card': card.reversed }]">
        </div>
      </div>
    </div>
    <!-- Container for Revealed Cards END -->

    <!-- Instructions for card selection -->
    <div class="instructions" v-show="showCardStack">
      Focus on "{{ userMessage }}" and choose 4 cards from the deck.
    </div>

    <!-- Card Selection Container -->
    <div class="cards-container" v-show="showCardStack">
      <div class="card" v-for="(card, index) in cards" :key="card.name"
           @click="selectCard(card.name)"
           :class="{ 'hidden-card': selectedCards.includes(card.name) }"
           :style="{ zIndex: cards.length - index }">
        <!-- Card Image Container -->
        <div class="card-image">
          <!-- Tarot Card Image (Front) -->
          <img v-if="selectedCards.includes(card.name)" :src="card.image_path" :alt="card.name"
               :class="['tarot-card', { 'reversed-card': card.reversed }]">
          <!-- Background Image (Card Back) -->
          <img v-else class="card-back"
               :src="`https://mysticaida.blob.core.windows.net/${azurePublicBucket}/static/card_images/Background/cardback3.jpg`"
               alt="Card Back">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showRevealedCards: {
      type: Boolean,
      required: true
    },
    revealedCards: {
      type: Array,
      required: true
    },
    showCardStack: {
      type: Boolean,
      required: true
    },
    userMessage: {
      type: String,
      required: true
    },
    cards: {
      type: Array,
      required: true
    },
    selectedCards: {
      type: Array,
      required: true
    },
    azurePublicBucket: {
      type: String,
      required: true
    }
  },
  methods: {
    selectCard(cardName) {
      this.$emit('select-card', cardName);
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>
