// StorageManager.js
export default class StorageManager {

  static clearCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  static clearLocalStorage() {
    localStorage.clear();
  }

  static clearSessionStorage() {
    sessionStorage.clear();
  }

  static clearAllStorage() {
    this.clearCookies();
    this.clearLocalStorage();
    this.clearSessionStorage();
  }
}

export function getUrlParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}


export const safeBool = (
  input,
  defaultValue = false,
) => {
  try {
    let newInput = input;
    if (typeof input === 'string') {
      newInput = input.toLowerCase();
    }
    if (newInput === 'true' || newInput === true || newInput === "yes" || newInput === "accepted") {
      return true;
    }
    if (newInput === 'false' || newInput === false || newInput === "no" || newInput === 'declined') {
      return false;
    }
    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const generateRandomAlphanumericString = (length = 9) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const setKeysFromSource = (sourceObj, destObj) => {
  Object.keys(sourceObj).forEach(key => {
    if (key in destObj && sourceObj[key] !== null && sourceObj[key] !== '') {
      destObj[key] = sourceObj[key];
    }
  });
}

export const parseAxiosError = (error) => {
  // Check for response error
  if (error.response) {

    if (error.response?.data?.password1?.[0]) {
      return error.response?.data?.password1?.[0]
    }
    if (error.response?.data?.username?.[0]) {
      return error.response?.data?.username?.[0]
    }
    // Prioritize response.data.non_field_errors[0] if it exists
    if (error.response.data?.non_field_errors?.[0]) {
      return error.response.data.non_field_errors[0];
    }

    // Prioritize response.data.error if it exists
    if (error.response.data && error.response.data.error) {
      return error.response.data.error;
    }
    // Then check for response.data.message
    else if (error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
    // Fallback to a general response error message
    return `Server responded with status code: ${error.response.status}`;
  }

  // Check for request error (no response)
  else if (error.request) {
    return 'No response was received for the request.';
  }

  // Generic error message (for other types of errors)
  else {
    return error.message || 'An unknown error occurred.';
  }
}

export const convertStringISODateToYYYYMMDDHHMM = (dateString) => {
  try {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return '';
    }

    const month = (date.getMonth() + 1); // Months are zero-based
    const day = date.getDate();
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${month}-${day}-${year} ${hours}:${minutes}`;
  } catch (error) {
    // If any error occurs, return an empty string
    return '';
  }
}

export const convertStringISODateToMMDDYYYY = (dateString) => {
  try {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return '';
    }

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const lastTwoDigitsOfYear = year.toString().slice(-2);

    return `${month}/${day}/${lastTwoDigitsOfYear}`;
  } catch (error) {
    // If any error occurs, return an empty string
    return '';
  }
}