<script setup>
    import { ref } from 'vue';
    import { loadReadingShare } from '@/api-service';
    import ResponseContainer from './ShareResponseContainer.vue'
    const showContainer = ref(false);

    const queryParams = new URLSearchParams(window.location.search);
    const shareUuid = queryParams.get('share_uuid');


    if (!shareUuid){
        localStorage.removeItem('reading_session_id');
        window.location.href = '/';
    }
    else{ //dffd55898e4b41a89b8d6111115b141b
        console.log('I made it here with UUID')
        loadReadingShare(shareUuid).then(response => {
        console.log("loadReadingShare response", response);
        showContainer.value = true;
        })
        .catch(error => {
            console.error("Error in loadReadingShare:", error);
            localStorage.removeItem('reading_session_id');
            window.location.href = '/';
        });
    }

</script>

<template>
    <div v-if="showContainer">
    <ResponseContainer />
    </div>
</template>