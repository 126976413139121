<template>
  <div>
    <!-- Login/Register Modal -->
    <transition name="fade">
      <div v-if="showLogin" class="login-modal-overlay">
        <div class="login-modal">
          <div class="x-modal-header">
            <close-icon class="close-button" @click="handleCloseClick"></close-icon>
          </div>
          <div class="hero-section" v-if="!forgotPasswordMode">
            <div v-if="loginCurrentTab === 'login'">
              <h1>See Beyond the Obvious</h1>
              <ul>
                  <li>Personalized:</li>
                  <li>Tarot Readings</li>
                  <li>Horoscopes</li>
                  <li>Coaching by Mystic AI-da</li>
              </ul>
            </div>
            <div v-if="loginCurrentTab === 'register'">
              <h1>See Beyond the Obvious</h1>
              <ul>
                  <li>Register for a 30 day free trial:</li>
                  <li>Unlimited Tarot Readings</li>
                  <li>Personalized Daily Horoscopes</li>
                  <li>Coaching by Mystic AI-da</li>
              </ul>

            </div>
          </div>
          <div class="modal-content">
            <login-social
              @loginSuccess="handleLoginSuccess"
              :login-methods="['password']"
              :currentTab="loginCurrentTab"
              :forgotPasswordMode="forgotPasswordMode"
              @updateTab="updateTab"
              @updateForgotPasswordMode="updateForgotPasswordMode"
            ></login-social>
          </div>
        </div>
      </div>
    </transition>
    <!-- Login/Register Modal End -->
  </div>
</template>

<script>
import CloseIcon from './CloseIcon.vue'; // Correct the path as needed
import LoginSocial from './LoginSocial.vue'; // Correct the path as needed

export default {
  components: {
    CloseIcon,
    LoginSocial,
  },
  props: {
    showLogin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loginCurrentTab: 'login', // Default to 'login'
      forgotPasswordMode: false,
    };
  },
  methods: {
    handleCloseClick() {
      this.$emit('update:showLogin', false);
    },
    handleLoginSuccess(keys) {
      this.$emit('loginSuccess', keys);
    },
    updateTab(tab) {
      this.loginCurrentTab = tab;
    },
    updateForgotPasswordMode(mode) {
      this.forgotPasswordMode = mode;
    },
    switchToRegister() {
      this.updateTab('register');
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: auto;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

input, textarea, select, button {
  font-size: 16px;
}




.submit-button {
  background-color: #9a71bd; /* Color when hovered over */
  color: white;
  border: none;
  padding: 20px 5px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 1.4em;
  transition: background-color 0.3s ease;
  margin: 10px 5px 5px 5px;
}

.modal-content {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  padding: 1px 1px 1px 1px;
}

.hero-section h1{
  font-size: 1.6em;
}

.hero-section {
  text-align: left; /* Left justify the text */
  padding: 20px;
  background: #663399;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}

.hero-content h1,
.hero-content ul,
.hero-content p {
  margin: 0 0 10px 0;
  padding: 0;


}

.hero-content ul {
  list-style-type: disc; /* Optional: change to disc if you prefer bullet points */
  padding-left: 20px; /* Indentation for list items */
}

.hero-content li {
  margin-bottom: 5px; /* Space between list items */
}

.hero-content p {
  margin-top: 10px; /* Space above the paragraph */
}

.hero-content a {
  color: #ffffff; /* Link color */
  cursor: pointer;
  text-decoration: underline; /* Optional: underline the link */
}

.hero-content a:hover {
  color: #cccccc; /* Optional: change color on hover */
}

.big-button {
  background-color: #663399;
  color: white;
  border: none;
  padding: 15px 15px;
  border-radius: 7.5px;
  cursor: pointer;
  font-size: 1.5em;
}

.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal {
  min-width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.x-modal-header {
  display: flex;
  justify-content: flex-end;

}

.x-modal-close-button {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: grey;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.hero-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hero-section ul li:first-child {
  font-weight: bold;
}

.hero-section ul li:not(:first-child) {
  margin-left: 20px; /* Adjust the indent as needed */
  text-indent: -20px; /* Align text with bullet point */
  list-style: disc inside;
}

.close-button{
  margin-top: 0;
  margin-bottom: 5px;
}
</style>
