<template>
  <div class="modal menu-modal">
    <div class="modal-content">
      <div class="x-modal-header">
        <close-icon @click="close"></close-icon>
      </div>
      <h3>Horoscope Settings</h3>
      <form @submit.prevent="submitForm">
        <div class="form-section">
          <label for="birth_date">Birth Date:</label>
          <input type="date" id="birth_date" v-model="formData.birth_date" />

          <label for="birth_time">Birth Time:</label>
          <input type="time" id="birth_time" v-model="formData.birth_time" />

          <!-- Hidden fields for birth latitude and longitude -->
          <input type="hidden" id="birth_latitude" v-model="formData.birth_latitude" />
          <input type="hidden" id="birth_longitude" v-model="formData.birth_longitude" />

          <!-- Placeholder for the map -->
          <label for="map">Birth Location:</label>
          <div id="map" class="map-placeholder"></div>
        </div>
        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CloseIcon from '@/components/ui/CloseIcon.vue';

export default {
  components: {
    CloseIcon,
  },
  name: 'HoroscopeSettings',
  data() {
    return {
      formData: {
        birth_date: '',
        birth_time: '',
        birth_latitude: '',
        birth_longitude: '',
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submitForm() {
      try {
        const response = await axios.post('/api/update-horoscope-settings', this.formData);
        console.log('Form submitted successfully:', response.data);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 2px;
  max-width: 600px;
  margin: 0 auto;
  z-index: -1;
}

.x-modal-header {
  display: flex;
  justify-content: flex-end;
}

form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 20px;
}

label {
  margin: 10px 0 5px;
  font-weight: bold;
}

input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  background-color: #28a745;
  color: white;
}

button:hover {
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
  .modal-content {
    padding: 10px;
  }

  input {
    padding: 6px;
  }

  .submit-button {
    padding: 8px;
  }
}

.map-placeholder {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
