<template>
  <div>
    <audio-player ref="audioPlayer"></audio-player>
  </div>
  <div class="page-container">
    <div class="content-wrap">
      <emerald-top-left
        :querentId="store.querent_id"
        :querentEmeraldBalance="store.querent_emerald_balance"
        :checkingSession="checkingSession"
        @showEmeraldModal="show_emerald_modal"
      ></emerald-top-left>
      <div>
        <top-right-profile
          :querentId="store.querent_id"
          @toggleMenu="toggleMenu"
          @showReadingHistoryModal="show_reading_history_modal"
          @showHoroscopeSettingsModal="show_horoscope_settings_modal"
          @signOut="sign_out"
          @update:showLogin="updateShowLogin"
          @showAccountModal="show_account_modal"
        ></top-right-profile>

        <authenticate-user
          :showWelcomeModal="showWelcomeModal"
          :showLogin="showLogin"
          @update:showWelcomeModal="showWelcomeModal = $event"
          @update:showLogin="showLogin = $event"
          @update:loginCurrentTab="store.login_current_tab = $event"
          @loginSuccess="setThisKeys"
        ></authenticate-user>

        <select-four-cards
          :showRevealedCards="showRevealedCards"
          :revealedCards="revealedCards"
          :showCardStack="showCardStack"
          :userMessage="userMessage"
          :cards="cards"
          :selectedCards="selectedCards"
          :azurePublicBucket="azurePublicBucket"
          @select-card="select_card"
        ></select-four-cards>

        <reading-four-cards
          :querentId="store.querent_id"
          :showResponseContainer="showResponseContainer"
          :show_full_reading="show_full_reading"
          :showRevealedCards="showRevealedCards"
          :userMessage="userMessage"
          :selectedCards="selectedCards"
          :card_readings="card_readings"
          :primary_reading="primary_reading"
          :messages="messages"
          :imageUrls="imageUrls"
          :loadingMessage="loadingMessage"
          :store="store"
          :isLoadingReading="isLoadingReading"
          :paid_for_reading="paid_for_reading"
          @do_a_new_reading="do_a_new_reading"
          @display_full_reading="display_full_reading"
          @show_chat_flyout="toggleChatFlyout"
          :showFlyout="showFlyout"
          :show_chat_button="show_chat_button"
          :is_shared="is_shared"
          :share_id="share_id"
          :sharing_state="sharing_state"
          :querent_name="querent_name"
          :querent_subscribed="querent_subscribed"
          @update:showLogin="updateShowLogin"
        />

        <chat-container
          :messages="messages"
          :isFetchingResponse="isFetchingResponse"
          :local_follow_up_user_message="follow_up_user_message"
          @update:local_follow_up_user_message="val => follow_up_user_message = val"
          @continueChat="continueChat"
          :showFlyout="showFlyout"
          @show_chat_flyout="toggleChatFlyout"
          @scrollChatBox="scrollChatBox"
        />

       <!-- Account Management-->
       <transition name="fade">
        <AccountManagement :querent_name="querent_name"
                           :querent_subscribed="querent_subscribed"
                           v-if="showAccountModal"
                           @close="hide_all_modals(true)"
                           @updateQuerentName="querent_name = $event"/>
       </transition>

  <!-- Horoscope Settings-->
    <transition name="fade">
      <horoscope-settings v-if="showHoroscopeSettingsModal" @close="hide_all_modals(true)"></horoscope-settings>
    </transition>

        <!-- Reading History Modal -->
        <transition name="fade">
          <div v-if="showReadingHistoryModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <reading-history></reading-history>
            </div>
          </div>
        </transition>

        <!-- Merch Shop -->
        <transition name="fade">
          <div v-if="showMerchShopModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <h3>Merch Shop</h3>
              <!-- Modal content for Merch Shop -->
            </div>
          </div>
        </transition>

        <!-- Initial Querent Message START-->
        <transition name="fade">
          <div v-if="showQuerentQuestionModal && !showWelcomeModal && !showLogin" class="modal querent-question-modal">
            <div class="modal-content">
              <form @submit.prevent="handleModalInitialMessage">
                <div style="padding-top: 8px;">
                  <label style="font-size: 18px; font-weight: bold;" for="message">What can I help you see?</label>
                  <textarea id="message" v-model="userMessage" rows="5" cols="50"></textarea>
                </div>
                <button class="standard-button" type="submit">Select Cards</button>
              </form>
            </div>
          </div>
        </transition>
        <!-- Initial Querent Message END-->

        <!-- Get Emeralds Modal -->
        <transition name="fade">
          <div v-if="showGetEmeraldsModal" class="modal menu-modal">
            <div class="modal-content">
              <div class="x-modal-header">
                <close-icon :on-click="() => hide_all_modals(true)"></close-icon>
              </div>
              <div v-if="checkoutStatus === 'checkout'">
                <div v-if="showStripeCardElement">
                  <div id="payment-element"><!-- Mount the Payment Element here --></div>
                  <div class="payment-button-container">
                    <button class="standard-button" @click="submitPayment" :disabled="store.is_loading">Submit
                      Payment</button>
                  </div>
                </div>
              </div>

<div v-if="checkoutStatus === ''">
  <div class="products-columns">
    <!-- Left Column: Non-Subscription Products -->
    <div class="products-container">
    <div class="hero">
      <h2>Emeralds</h2>
      <p class="hero-subtext">Pay Per Reading</p>
    </div>

      <div v-for="product in otherProducts" :key="product.stripe_product_id" class="product-card">
        <div class="product-card-columns">
          <div class="product-image-container">
            <img :src="product.image" alt="Product Image" class="product-image">
          </div>
          <div class="product-info">
            <h3><b class="product-name">{{ product.name }}</b></h3>
            <p class="tag_line">{{ product.tag_line }}</p>
            <div class="price-info">
              <p class="price">
                <button @click="handleBuyButtonClick(product.default_prices[0].stripe_price_id)"
                  class="standard-button buy-button">Buy</button> for ${{ product.default_prices[0].price }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column: Subscription Products -->
    <div class="products-container" v-if="!querent_subscribed">
      <div class="hero">
        <h2>Subscription</h2>
        <p class="hero-subtext">Unlimited Readings</p>
      </div>
      <div v-for="product in subscriptionProducts" :key="product.stripe_product_id" class="product-card">
        <div class="product-card-columns">
          <div class="product-image-container">
            <img :src="product.image" alt="Product Image" class="product-image">
          </div>
          <div class="product-info">
            <h3><b class="product-name">{{ product.name }}</b></h3>
            <p class="tag_line">{{ product.tag_line }}<i class="fas fa-gem" title="Extra Emeralds"></i></p>
            <div class="price-info">
              <p class="price">
                <button @click="handleBuyButtonClick(product.default_prices[0].stripe_price_id)"
                  class="standard-button buy-button">Subscribe</button> for ${{ product.default_prices[0].price }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

              <div v-else-if="checkoutStatus === 'success'" class="hero">
                <h1>Thank you for your purchase!</h1>
                <p class="hero-subtext" v-if="querent_subscribed">Your access to endless readings lasts until
                  {{ formattedSubscriptionExpiration }}.</p>
                <p class="hero-subtext" >You've acquired {{ emeraldsPurchased }} emeralds.
                  <i class="fas fa-gem" title="Extra Emeralds"></i>
                </p>
                <button @click="do_a_new_reading">
                  Reveal Your Next Insight
                </button>
              </div>

              <div v-else-if="checkoutStatus === 'cancel'">
                <p>Forgot to add something to your cart? Shop around then come back to pay!</p>
              </div>
            </div>
          </div>
        </transition>

        <!-- Main Container END -->
      </div>
    </div>
    <footer class="sticky-footer">
      © {{ currentYear }} TechStart Innovators |
      <a href="mailto:support@mysticAIda.ai">Support</a>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { store, static_store } from '@/store'
import AccountManagement from '@/components/ui/modals/AccountManagement.vue';
import HoroscopeSettings from '@/components/ui/modals/HoroscopeSettings.vue';
import AudioPlayer from '@/components/ui/AudioPlayer.vue';
import EmeraldTopLeft from '@/components/ui/TopLeftStore.vue';
import TopRightProfile from '@/components/ui/TopRightProfile.vue';
import AuthenticateUser from '@/components/ui/AuthenticateUser.vue';
import SelectFourCards from '@/components/ui/games/FourCardsSelect.vue';
import ReadingFourCards from '@/components/ui/games/FourCardsReading.vue';
import ChatContainer from '@/components/ui/ChatContainer.vue';

import axios from 'axios';
axios.defaults.withCredentials = true;
import { mockReading } from '@/mocks';
import ReadingHistory from './ui/modals/ReadingHistory.vue'
import CloseIcon from './ui/CloseIcon.vue'
import StorageManager from '../utils/general-utils';
import {getUrlParam} from "@/utils/general-utils";
import { safeBool } from '@/utils/general-utils';
import { getQuerent } from '@/api-service'
import {format} from "date-fns";


if (!mockReading) {
  console.log("we need a reference to mockReading to avoid lint errors. This is for debugging.")
}

export default {
  components: {
    AudioPlayer,
    EmeraldTopLeft,
    TopRightProfile,
    AuthenticateUser,
    SelectFourCards,
    ReadingFourCards,
    ReadingHistory,
    CloseIcon,
    ChatContainer,
    AccountManagement,
    HoroscopeSettings,
  },
  setup() {
    const follow_up_user_message = ref('');
    const clearFollowUpUserMessage = () => {
      follow_up_user_message.value = '';
    };
    return {
      follow_up_user_message,
      clearFollowUpUserMessage,
    };
  },
  data() {
    return {
      emeraldAudioPlayer: null,
      webSocketURL: process.env.VUE_APP_WEB_SOCKET_URL,
      azurePublicBucket: process.env.VUE_APP_AZURE_PUBLIC_BUCKET,
      store: store,
      static_store: static_store,
      currentYear: new Date().getFullYear(),
      showCardStack: false,
      showMenu: false,
      userMessage: '',
      selectedCards: [],
      cards: [],
      showQuerentQuestionModal: false,
      revealedCards: [],
      imageUrl: '',
      progress_indicator: 'welcome-modal',
      card_1: '',
      card_2: '',
      card_3: '',
      card_4: '',
      card_readings: {
        card_1: '',
        card_2: '',
        card_3: '',
        card_4: '',
      },
      primary_reading: '',
      showResponseContainer: false,
      follow_up_question: '',
      showLogin: false,
      initial_reading_package: '',
      querent_created: false,
      follow_up_response: '',
      messages: [],
      showRevealedCards: true,
      show_full_reading: false,
      isFetchingResponse: false,
      imageUrls: [],
      showGetEmeraldsModal: false,
      showAccountModal: false,
      showHoroscopeSettingsModal: false,
      showReadingHistoryModal: false,
      showMerchShopModal: false,
      readerProducts: [],
      checkoutStatus: '',
      ws: null,
      stripe: null,
      cardElement: null,
      showStripeCardElement: false,
      purchase_key: null,
      client_secret: null,
      payment_element: null,
      purchase_intent_id: null,
      paid_for_reading: false,
      cardDescriptions: [],
      delayedReadingTimout: null,
      allowFullReading: false,
      streamingChat: false,
      streamChatToMessageId: -1,
      loadingMessage: '',
      checkingSession: true,
      showWelcomeModal: false,
      isLoadingReading: false,
      showFlyout: false,
      is_shared:false,
      share_id:null,
      sharing_state:false,
      show_chat_button:false,
      querent_name:'',
      querent_subscribed:false,
      emeraldsPurchased: 0
    };
  },
  mounted() {
    this.emeraldAudioPlayer = this.$refs.emeraldAudioPlayer;
    const loadingMessages = [
      "Gazing into the orb... Your vision is crystallizing. Patience, the magic unfolds!",
      "Divining insights... Hold tight as the crystal reveals its secrets.",
      "Unraveling mysteries... Await a moment as the sphere enlightens us.",
      "The future is becoming clear... Stay with me as I focus the crystal's gaze.",
      "Seeking foresight... Let the crystal ball illuminate your path. Almost there!",
      "Channeling visions... The crystal is aligning with your destiny. Hold on!",
      "Scrying the unseen... The orb's light is weaving your tale. Stay tuned!",
      "Crystal clarity ahead... Anticipate a moment as the orb's magic awakens.",
      "Oracular insights in progress... The crystal is shaping your narrative. Patience!",
      "Eclipsing the unknown... As the orb glows, so does your revelation. Momentarily!"
    ]
    this.loadingMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];

    this.checkForSession();
    this.handleOAuthCallback();

    let url = this.static_store.apiUrl + '/reading/get-four-card-spread/?';

    if (this.store.reading_session_id) {
      url += `reading_session_id=${encodeURIComponent(this.store.reading_session_id)}`;
      url += `&querent_id=${encodeURIComponent(this.store.querent_id)}`;
    } else {
      if (this.store.querent_id) {
        url += `querent_id=${encodeURIComponent(this.store.querent_id)}`;
        this.showQuerentQuestionModal = true;
      }
    }
    const url_param_reader = getUrlParam('r');
    if (url_param_reader != null){
      url += `&reader_id=${encodeURIComponent(url_param_reader)}`;
    }

    axios.get(url)  // Load all cards for the select options
        .then(response => {
          this.cards = response.data.cards;
          this.querent_subscribed = response.data.querent_subscribed;
          this.store.querent_subscription_expiration = response.data.querent_subscription_expiration;

          const server_session_id = response.data.reading_session_id
          if (this.store.reading_session_id !== server_session_id) {
            localStorage.setItem('reading_session_id', server_session_id);
            this.store.reading_session_id = response.data.reading_session_id
          }
          this.connectWebSocket();
        })
        .catch(error => console.error(error));
    },
  beforeUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  },
  watch: {
    showGetEmeraldsModal(newValue) {
      if (newValue === true) {
        this.showStripeCardElement = true;
        this.initializeStripe();
      }
      this.checkoutStatus = ''
    }
  },
  computed: {
    formattedSubscriptionExpiration() {
    return format(new Date(this.store.querent_subscription_expiration), 'MM-dd-yyyy');
    },
    subscriptionProducts() {
      return this.readerProducts.filter(product => product.product_category === 'Subscription');
    },
    otherProducts() {
      return this.readerProducts.filter(product => product.product_category !== 'Subscription');
    },
    filteredCards() {
      return this.cards.filter(card => this.selectedCards.includes(card.name));
    },
    isPlayingEmeraldSound() {
      return this.emeraldAudioPlayer && !this.emeraldAudioPlayer.paused && !this.emeraldAudioPlayer.ended && this.emeraldAudioPlayer.currentTime > 0;
    },
    metaDescription() {
      const past = this.card_readings['card_1'];
      const present = this.card_readings['card_2'];


      return `Past: ${past}. Present: ${present}.`;
    }
  },
  methods: {
    preloadImages(imagesToPreload) {
      imagesToPreload.forEach(url => {
        const img = new Image();
        img.src = url;
      });
    },
    formatText(text) {
      return text.replace(/\n/g, '<br>');
    },
    aModalIsOpen() {
      return this.showGetEmeraldsModal || this.showAccountModal || this.showReadingHistoryModal || this.showMerchShopModal;
    },
    scrollChatBox() {
      setTimeout(() => {
        const textarea = this.$refs.chat_container_ref;
        if (textarea) {
          textarea.scrollTop = textarea.scrollHeight;
        }
      }, 100);
    },
    connectWebSocket() {
      this.ws = new WebSocket(this.webSocketURL + '/ws/emeralds/?socket_session_id=' + this.store.reading_session_id)
      this.ws.onopen = () => {
        console.log('WebSocket connected');
      };
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.context) {
          switch (data.context) {
            case 'card_reading':
              this.card_readings['card_' + data.reference] += data.message
              break;
            case 'primary_reading':
              this.primary_reading += data.message
              break;
            case 'follow_up_question':
              if (this.messages.length === 0) {
                this.messages.push({text: data.message, type: 'reader'});
                this.scrollChatBox()
              } else {
                this.messages[0].text += data.message
                this.scrollChatBox()
              }
              break;
            case 'progress':
              this.progress_indicator = data.message
              if (data.message === 'Summarize reading') {
                this.isLoadingReading = false;
              }
              break;
            case 'chat-box':
              if (this.messages[this.streamChatToMessageId]) {
                this.messages[this.streamChatToMessageId].text += data.message
                this.scrollChatBox()
              } else {
                this.messages.push({text: data.message, type: 'reader'});
                this.scrollChatBox()
              }
              break;
            case 'other':
              console.log("set other", data)
              break;
            default:
              console.log('Received socket chat message:', data);
          }
        }
      };
      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      this.ws.onclose = () => {
        console.log('WebSocket disconnected');
      };
    },
    async checkForSession() {
      this.checkingSession = true;
      this.store.reading_session_id = localStorage.getItem('reading_session_id') || '';
      this.store.querent_id = localStorage.getItem('querent_id')
      if (this.store.querent_id === null) {
            this.store.querent_id = ''
          }
      const url_param_reading_session_id = getUrlParam('rs');
      if (url_param_reading_session_id) {
        this.store.reading_session_id = url_param_reading_session_id
        localStorage.setItem('loadSession', true);
      }
      if (this.store.reading_session_id) {
        let loadSession = safeBool(localStorage.getItem('loadSession'), false)
        loadSession = true;
        if (loadSession === true) {
            try {

              const response = await axios.post(this.static_store.apiUrl + '/reading/load-reading-session/', {
                reading_session_id: this.store.reading_session_id,
                querent_id: this.store.querent_id
              });
              this.querent_name = response.data.querent_name
              this.store.querent_name = response.data.querent_name
              this.is_shared = response.data.shared
              this.sharing_state = response.data.sharing_state
              this.share_id = response.data.share_id

              if (response.data.authorized){
                if (this.is_shared){
                  this.paid_for_reading = true;
                  this.show_full_reading = true;
                  this.showFlyout = false;
                  this.show_chat_button = true
                }
                else{
                  this.show_chat_button = false
                }
              }
              else{
                  this.paid_for_reading = false;
                  this.show_full_reading = false;
                  this.showFlyout = false;
                  this.$notify({
                    text: 'You are not authorized to see this reading.',
                    type: 'error',
                    duration: 5000
                  })
              }
              this.show_chat_button = !this.is_shared;
              if (response.data?.selected_cards?.length >= 4) {
                this.selectedCards = response.data.selected_cards
                this.handleReadingResponse(response)
                this.showQuerentQuestionModal = false;
                this.allowFullReading = true
              }
              if (response.data?.is_paid) {
                this.showFlyout = false;
                this.paid_for_reading = true
                this.show_full_reading = true;
                this.allowFullReading = true
                this.show_chat_button = true
              }
            } catch (error) {
              if (error.response && error.response.status === 404) {
                console.error('Reading not found.');
                alert('Reading not found.');
              } else {
                console.error('An error occurred:', error);
                alert('An unexpected error occurred. Please try again later.');
              }
            }
        }
      }
      if (this.store.querent_id) {
        await getQuerent(this.store.querent_id)
        this.progress_indicator = 'initial'
        this.showQuerentQuestionModal = true;
      }
      else {
          this.progress_indicator = 'initial'
          this.showQuerentQuestionModal = true;
      }
      this.checkingSession = false;

    },
    async handleBuyButtonClick(stripe_price_id) {
      try {
        const data = {stripe_price_id: stripe_price_id, querent_id: this.store.querent_id};
        const response = await axios.post(this.static_store.apiUrl + '/stripe-create-payment-intent/', data);
        const client_secret = response.data.clientSecret;
        this.showStripeCardElement = true;
        this.checkoutStatus = 'checkout';
        this.client_secret = client_secret;
        this.purchase_intent_id = response.data.purchase_intent_id;

        const appearance = {theme: 'stripe',};
        const Stripe = await this.loadStripe();

        this.stripe = Stripe(this.static_store.stripe_public_key);

        const elements = this.stripe.elements({clientSecret: client_secret, appearance: appearance});
        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");

        this.elements = elements
        this.payment_element = paymentElement

      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    },
    async submitPayment() {
      this.store.is_loading = true;
      this.checkoutStatus = 'checkout';
      const client_secret = this.client_secret;
      const elements = this.elements;

      try {
        const submissionResult = await elements.submit();

        if (submissionResult.error) {
          alert(submissionResult.error.message);
          return;
        }

        const {error} = await this.stripe.confirmPayment({
          elements: elements,
          clientSecret: client_secret,
          confirmParams: {
            return_url: this.static_store.apiUrl + '/stripe-payment-success/',
          },
          redirect: "if_required",
        });

        if (error) {
          this.checkoutStatus = 'cancel';
        } else {
          this.checkoutStatus = 'success';
          let url_address = this.static_store.apiUrl + `/stripe-payment-success/?`;
          url_address += `querent_purchase_intent_id=${encodeURIComponent(this.purchase_intent_id)}`;
          url_address += `&client_secret=${encodeURIComponent(client_secret)}`;
          const response = await axios.get(url_address);

          const previousEmeraldBalance = this.store.querent_emerald_balance;
          const newEmeraldBalance = response.data.emeralds_balance;
          const emeraldsPurchased = newEmeraldBalance - previousEmeraldBalance;

          this.animateEmeraldsCount(previousEmeraldBalance, newEmeraldBalance);
          this.querent_subscribed = response.data.querent_subscribed;
          this.store.querent_subscription_expiration = response.data.querent_subscription_expiration;
          this.emeraldsPurchased = emeraldsPurchased;
        }
      } catch (error) {
        console.error('Error during the payment process:', error);
        alert('An error occurred during the payment process.');
      } finally {
        this.store.is_loading = false;
      }
    },
    animateEmeraldsCount(oldBalance, newBalance) {
      let startValue = oldBalance;
      let endValue = newBalance;
      let incrementAmount = endValue - startValue;
      let baseDuration = 1000;
      let maxDuration = 15000;
      let minDuration = 5000;

      let duration = Math.max(minDuration, Math.min(maxDuration, baseDuration - incrementAmount * 10));

      let stepTime = 50;
      let steps = duration / stepTime;
      let increment = incrementAmount / steps;
      let interval = setInterval(() => {
        this.$refs.audioPlayer.playEmeraldSound();
        this.store.querent_emerald_balance = Math.round(startValue)
        startValue += increment;
        oldBalance = Math.round(startValue);
        if (startValue >= endValue) {
          clearInterval(interval);
          this.store.querent_emerald_balance = endValue;
        }
      }, stepTime);
    },
    async loadStripe() {
      if (window.Stripe) {
        return window.Stripe;
      }
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.onload = () => resolve(window.Stripe);
        document.head.appendChild(script);
      });
    },
    async initializeStripe() {
      if (this.stripe) {
        return;
      }
      try {
        const Stripe = await this.loadStripe();
        this.stripe = Stripe(this.static_store.stripe_public_key);

      } catch (error) {
        console.error("Error loading Stripe:", error);
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    do_a_new_reading() {
      localStorage.removeItem('reading_session_id')
      const url_param_reader = getUrlParam('r');
      let qs = ''
      if (url_param_reader != null){
        qs = `?r=${encodeURIComponent(url_param_reader)}`;
      }
      this.showFlyout = false;
      window.location.href = '/' + qs;
    },
    toggleChatFlyout() {
      // Toggle the chat window.
      this.showFlyout = !this.showFlyout;
    },
    hideRevealedCards() {
      this.showRevealedCards = false;
    },
    async fetchReaderProducts() {
      try {
        const response = await axios.get(this.static_store.apiUrl + '/querent/get-emeralds/');
        this.readerProducts = response.data.products;
      } catch (error) {
        console.error("Error fetching reader products:", error);
      }
    },
    hide_all_modals(checkForInitial = false) {
      this.showQuerentQuestionModal = false;
      this.showGetEmeraldsModal = false;
      this.showMenu = false;
      this.showAccountModal = false;
      this.showReadingHistoryModal = false;
      this.showMerchShopModal = false;
      this.showHoroscopeSettingsModal = false;
      if (checkForInitial) {
        if (this.progress_indicator === 'initial') {
          this.showQuerentQuestionModal = true;
        }
      }
    },
    async show_emerald_modal() {
      await this.fetchReaderProducts();
      this.hide_all_modals();
      this.showGetEmeraldsModal = true;
    },
    show_account_modal() {
      this.hide_all_modals();
      this.showAccountModal = true;
    },
    show_horoscope_settings_modal() {
      this.hide_all_modals();
      this.showHoroscopeSettingsModal = true;
    },
    async show_reading_history_modal() {
      try {
        this.store.is_loading = true;
        await getQuerent(this.store.querent_id)
        this.hide_all_modals();
        this.showReadingHistoryModal = true;
      } catch (error) {
        console.error("Error fetching reader history:", error);
      } finally {
        this.store.is_loading = false;
      }
    },
    show_merch_shop_modal() {
      console("SHOWING UP MERCH SHOP")
      this.hide_all_modals();
      this.showMerchShopModal = true;
    },
    sign_out() {
      StorageManager.clearAllStorage();
      window.location.href = '/';
    },
    updateShowLogin(value) {
      this.showLogin = value;
    },
    async display_full_reading() {
        if (!this.store.querent_id) {
          this.showLogin = true;
        } else {
        if (!this.paid_for_reading && this.store.querent_emerald_balance >= 25) {
          try {
            await this.deductEmeralds();
            this.paid_for_reading = true;
            this.show_full_reading = true;
            this.showFlyout = false;
            this.$refs.audioPlayer.playSelectSound();
          } catch (error) {
            console.error("Error deducting emeralds:", error);
          }
        } else {
          if (!this.is_shared){
            await this.show_emerald_modal()
          }
        }
      }
    },
    async deductEmeralds() {
      try {
        const response = await axios.get(this.static_store.apiUrl + '/querent/charge-for-reading/', {
          params: {
            querent_id: this.store.querent_id,
            reading_session_id: this.store.reading_session_id
          }
        });

        if (response.data.status === 'success') {
          this.store.querent_emerald_balance = response.data.emeralds_balance;
          this.querent_subscribed = response.data.querent_subscribed;
          this.store.querent_subscription_expiration = response.data.querent_subscription_expiration;
        } else {
          console.error('Error charging for reading:', response.data.error);
        }
      } catch (error) {
        console.error('Network or other error:', error);
      }
    },
    handleModalInitialMessage() {
      if (this.userMessage === '') {
        this.$notify({
          text: 'What do you want to say?',
          type: 'info',
        })
      } else {
        this.showQuerentQuestionModal = false;
        this.showCardStack = true;
        this.progress_indicator = 'Selecting cards...'
      }
    },
    select_card(cardName) {
      if (this.selectedCards.length >= 4) {
        return;
      }
      this.$refs.audioPlayer.playSelectCardSound();
      const index = this.cards.findIndex(card => card.name === cardName);
      if (index !== -1) {
        const selectedCard = {
          name: this.cards[index].name,
          reversed: this.cards[index].reversed,
          description: this.cards[index].description,
          reverse_description: this.cards[index].reverse_description,
          image_path: this.cards[index].image_path
        };
        this.revealedCards.push(this.cards[index]);
        this.selectedCards.push(selectedCard);

        const description = selectedCard.reversed ? selectedCard.reverse_description : selectedCard.description;
        this.cardDescriptions.push(description);

        this.cards.splice(index, 1);

        if (this.selectedCards.length >= 4) {
          this.getCardReading();
          this.delayedReadingTimout = setTimeout(() => {
            this.showResponseContainer = true
            this.readingCompleted = false;
            this.hideRevealedCards();
          }, 2000);
        }
      }
    },
    setThisKeys(keys) {
      for (const [key, value] of Object.entries(keys)) {
        this[key] = value
      }
      if(this.showResponseContainer){
        this.display_full_reading()
      }

    },

    async handleOAuthCallback() {
      const authToken = localStorage.getItem('authToken');
      const querentId = localStorage.getItem('querent_id');
      if (!authToken || !querentId) {
        const code = getUrlParam('code');
        if (code) {
          try {
            const response = await axios.post(this.static_store.apiUrl + '/dj-rest-auth/google/', {
              code: code,
              redirect_uri: window.location.origin,
              reading_session_id: this.store.reading_session_id,
            }, {
              headers: {
                'X-CSRFToken': localStorage.getItem('csrfToken')
              }
            });
            if (response.data.querent_id) {
              localStorage.setItem('authToken', response.data.key);
              localStorage.setItem('querent_id', response.data.querent_id);
              this.store.querent_id = response.data.querent_id
              this.$notify({
                text: 'Login Successful',
                type: 'success',
              })
            }
          } catch (error) {
            console.error('Error exchanging code for token:', error);
          }
        } else {
          console.log("no google code from url, verify token here?")
        }
      } else {
        if (querentId) {
          this.store.querent_id = querentId
        }
      }
    },
    handleReadingResponse(response) {
      clearTimeout(this.delayedReadingTimout);
      this.showResponseContainer = true
      this.hideRevealedCards();
      this.initial_reading_package = response;
      this.imageUrls.push(response.data.image_url);
      this.card_1 = response.data.card_1
      this.card_2 = response.data.card_2
      this.card_3 = response.data.card_3
      this.card_4 = response.data.card_4
      if (response.data.reading) {
        this.primary_reading = response.data.reading
      }
      if (this.selectedCards.length >= 4) {
        this.selectedCards[0].description = response.data.card_1;
        this.selectedCards[1].description = response.data.card_2;
        this.selectedCards[2].description = response.data.card_3;
        this.selectedCards[3].description = response.data.card_4;
        this.progress_indicator = 'Reading complete.'
        this.card_readings.card_1 = response.data.card_1;
        this.card_readings.card_2 = response.data.card_2;
        this.card_readings.card_3 = response.data.card_3;
        this.card_readings.card_4 = response.data.card_4;
      }
      this.follow_up_question = response.data.follow_up_question;
      if (this.messages.length === 0) {
        this.messages.push({text: this.follow_up_question, type: 'reader'});
        this.scrollChatBox()
      } else {
        this.messages[0].text = this.follow_up_question
        this.scrollChatBox()
      }
      if (response?.data?.reading_session_conversations?.length > 0) {
        response.data.reading_session_conversations.map((message) => {
          if (message.type === 'CHAT') {
            let message_type = 'user'
            if (message.from_reader) {
              message_type = 'reader'
            }
            this.messages.push({text: message.message, type: message_type});
          }
        });
        this.scrollChatBox()
      }
      if (response?.data?.user_message) {
        this.userMessage = response.data.user_message
      }
      this.isLoadingReading = false;
      this.readingCompleted = true;
    },
    test_auth() {
      const token = localStorage.getItem('authToken')
      axios.post(this.static_store.apiUrl + '/reading/test/', {}, {
        headers: {
          Authorization: `Token ${token}`
        }
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.error('Error:', error);
      });
    },
    async getCardReading() {
      if (this.isLoadingReading) {
        return;
      }
      this.isLoadingReading = true;
      this.progress_indicator = 'Reading your cards..'
      this.showCardStack = false;

      const postData = {
        message: this.userMessage,
        selected_cards: this.selectedCards,
        reading_session_id: this.store.reading_session_id,
        querent_id: this.store.querent_id,
      };

      postData.socket_session_id = this.store.reading_session_id

      try {
        const response = await axios.post(this.static_store.apiUrl + '/reading/querent/', postData)
        this.handleReadingResponse(response)
      } catch (error) {
        console.error(error);
        this.readingCompleted = true;
      } finally {
        this.isLoadingReading = false;
      }
    },
    continueChat() {
      if (!this.follow_up_user_message || this.streamingChat) {
        return;
      }
      this.streamingChat = true;
      this.messages.push({text: this.follow_up_user_message, type: 'user'});
      this.scrollChatBox()
      this.streamChatToMessageId = this.messages.length;

      const postData = {
        user_message: this.follow_up_user_message,
        reading_session_id: this.store.reading_session_id,
        socket_session_id: this.store.reading_session_id,
      };
      this.follow_up_user_message = '';
      axios.post(this.static_store.apiUrl + '/reading/querent-follow-up/', postData)
          .then(response => {
            if (response.data?.message) {
              if (this.messages[this.streamChatToMessageId]) {
                this.messages[this.streamChatToMessageId].text = response.data.message
                this.scrollChatBox()
              } else {
                this.messages.push({text: response.data.message, type: 'reader'});
                this.scrollChatBox()
              }
            }
            if (response.data?.querent_emerald_balance) {
              this.store.querent_emerald_balance = response.data.querent_emerald_balance;
            }
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            this.streamingChat = false
          });
    }
  }
};
</script>
<style scoped>
.products-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.products-container {
  width: 95%;

}

.product-card {
  width: 100%;
  min-height: 160px; /* Set a minimum height for the cards */
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly */
}

.product-card-columns {
  display: grid;
  grid-template-columns: 80px auto;
  width: 100%;
  gap: 10px;
  align-items: start; /* Align items to the start (top) */
}

.product-image-container {
  text-align: left;
}

.product-image {
  width: 100px; /* Set a fixed width for the images */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure images fit within the height without distortion */
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure full height for the product info */
  width: 100%; /* Ensure full width for the product info */
  margin: 0 0 0 25px;

}

.product-name {
  margin: 0 0 0 5px; /* Ensure no extra margin */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.price-info {
  display: flex;
  flex-direction: column; /* Align price and button vertically */
  justify-content: flex-start; /* Align to the top */
  margin-top: 5px;
}

.standard-button {
  background-color: #4CAF50;
  color: white;
  padding: 20px 30px 20px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0; /* Add margin between price and button */
}

.standard-button:hover {
  background-color: #45a049;
}

.tag_line {
  margin: 0 0 0 5px;
}
.hero {
  text-align: center;
  margin-bottom: 20px;
  background-color: #f3e8ff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width:100%
}

.hero-subtext {
  font-size: 1.2em;
  color: #663399;
  margin-top: 10px;
}

.fas.fa-gem {
  color: #50C878;
  margin-left: 10px;
}


@media screen and (max-width: 768px) {
  .products-columns {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
</style>

<style src="../styles/user-reading-form.css"></style>
