// apiService.js
import { store, static_store } from './store.js'; // Import the store
import axios from 'axios';
axios.defaults.withCredentials = true;
import { API_URL } from './constants.js';
import { setKeysFromSource, parseAxiosError } from './utils/general-utils.js'

async function getQuerent(querent_id) {
  try {
    const response = await axios.get(API_URL + '/reading/get-querent?querent_id=' + querent_id);
    if (response?.data){
      setKeysFromSource(response.data, store)
    }
    if (response?.data?.reading_sessions){
      store.reading_sessions = response.data.reading_sessions.filter((x) => x.initial_question)
    }
    return response
  } catch (error) {
    console.error('Error fetching querent:', error);
  }
}

async function setQuerentOnReading(querent_id, reading_session_id) {
  try {
    const response = await axios.post(API_URL + '/reading/set-querent/',
        { querent_id, reading_session_id });
    console.log('setQuerentOnReading response', response)
    return response
  } catch (error) {
    console.error('Error fetching querent:', error);
  }
}

async function resetPassword(token, password) {
  try {
    const response = await axios.post(API_URL + '/auth/reset-password/', { token, password });
    console.log('resetting password', response)
    return response
  } catch (error) {
    console.error('Error resetting password:', error);
    return { status: 'fail', message: parseAxiosError(error) }
  }
}

async function toggleShare(is_shared) {
  try {
    const response = await axios.post(API_URL + '/reading/toggle-share/', {
      reading_session_id: store.reading_session_id,
      is_shared: is_shared
    });
    if (response?.data?.reading_session){
      store.reading_session = response.data.reading_session
    }
    return response
  } catch (error) {
    console.error('Error fetching querent:', error);
  }
}
async function loadReadingShare(share_uuid) {
  try {
    console.log("trying load reading share", share_uuid)
    const response = await axios.post(API_URL + '/reading/load-reading-share/', {
      share_uuid: share_uuid
    });
    setStoreReadingResponseValues(response)
    if (response?.data?.reading_sesion){
      store.reading_session = response.data.reading_sesion
    }
    console.log('loadReadingSession response', response)
    console.log("store", store)
    return response
  } catch (error) {
    console.error('Error fetching querent:', error);
    throw error
  }
}
async function getFullReading(postData) {
  const response = await axios.post(static_store.apiUrl + '/reading/querent/', postData)
  setStoreReadingResponseValues(response)
  return response;
}
function setStoreReadingResponseValues(response){
  if (response.data?.selected_cards?.length >= 4) { // go to reading
    store.selectedCards = response.data.selected_cards
    store.allowFullReading = true
    store.showResponseContainer = true
  }
  if (response.data?.is_paid) {
    store.paid_for_reading = true
    store.show_full_reading = true;
    store.allowFullReading = true
  }

  store.imageUrls.push(response.data.image_url);
  if (response.data.reading) {
    store.primary_reading = response.data.reading
  }
  if (store.selectedCards.length >= 4) {
    store.selectedCards[0].description = response.data.card_1;
    store.selectedCards[1].description = response.data.card_2;
    store.selectedCards[2].description = response.data.card_3;
    store.selectedCards[3].description = response.data.card_4;
    // override any socket messages in case of broken sockets
    store.card_readings.card_1 = response.data.card_1;
    store.card_readings.card_2 = response.data.card_2;
    store.card_readings.card_3 = response.data.card_3;
    store.card_readings.card_4 = response.data.card_4;
  }
  store.follow_up_question = response.data.follow_up_question;
  if (store.messages.length === 0) {
    store.messages.push({ text: store.follow_up_question, type: 'reader' });
  }
  else {
    store.messages[0].text = store.follow_up_question
  }
  if (response?.data?.reading_session_conversations?.length > 0) {
    response.data.reading_session_conversations.map((message) => {
      if (message.type === 'ConversationType.CHAT') {
        let message_type = 'user'
        if (message.from_reader) {
          message_type = 'reader'
        }
        store.messages.push({ text: message.message, type: message_type });
      }
    });
  }
  if (response?.data?.user_message) {
    store.userMessage = response.data.user_message
  }
  store.isLoadingReading = false;
}
export { getQuerent, setQuerentOnReading, resetPassword, getFullReading, loadReadingShare, toggleShare };