<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  onClick: Function
})
</script>

<template>
  <i @click="props.onClick" style="" class="far fa-times-circle close-icon"></i>
</template>

<style>
.close-icon {
  color: #663399;
  font-size: 32px; 
  cursor: pointer;
  transition: color 0.3s ease; /* Add this line */
}

.close-icon:hover {
    color: #7c51a8;
}
</style>