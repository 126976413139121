<script setup>
import { store } from '@/store'
import { convertStringISODateToMMDDYYYY } from '@/utils/general-utils'

// Methods
async function loadSession(reading_session_id) {
    window.location = '/?rs=' + reading_session_id;
}

</script>

<template>
    <div class="table-container">
      <h3>Reading History</h3>
      <table class="history-table">
        <thead>
          <tr>
            <th>View</th>
            <th>Shared</th>
            <th>Date</th>
            <th>Initial Question</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reading_session in store.reading_sessions" :key="reading_session.uuid">
            <td :style="{ backgroundColor: reading_session.is_paid ? '#90EE90' : '', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
              <i style="cursor: pointer;" @click="loadSession(reading_session.uuid)" class="fa fa-eye"></i>
            </td>
            <td>{{ reading_session.is_shared }}</td>
            <td>{{ convertStringISODateToMMDDYYYY(reading_session.start_time) }}</td>
            <td>{{ reading_session.initial_question }}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </template>

  <style>
  .table-container {
    overflow-x: auto; /* Enables horizontal scrolling for smaller screens */
    margin-top: 16px;
  }

  .history-table {
    width: 100%;
    border-collapse: collapse; /* Ensures borders collapse */
  }

  .history-table th, .history-table td {
    padding: 8px; /* Adds padding to table cells */
    text-align: left;
    border-bottom: 1px solid #ddd; /* Bottom border for each row */
  }

  .history-table thead {
    background-color: #f2f2f2; /* Light grey background for the header */
  }

  .history-table tbody tr:nth-child(odd) {
    background-color: #fff; /* White background for odd rows */
  }

  .history-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light grey background for even rows */
  }

  .history-table th {
    font-weight: bold; /* Makes header text bold */
  }

  .fa-eye {
    cursor: pointer; /* Changes cursor to pointer when hovering over the icon */
    color: #663399
  }
  </style>
