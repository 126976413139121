<template>
  <div :style="backgroundStyle">
    <UserReadingForm />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import UserReadingForm from '../components/GameFourCardDraw.vue';
import {getUrlParam} from "@/utils/general-utils";

const backgroundStyle = ref({
  backgroundImage: "url('https://mysticaida.blob.core.windows.net/public/static/MysticAidaV2.png')",
  backgroundColor: '#181818',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'contain',
  minHeight: '100vh',
  overflow: 'auto',
  margin: 0,
});


const url_param_reader = getUrlParam('r');
if (url_param_reader === '52') {
  backgroundStyle.value.backgroundImage = "url('https://mysticaida.blob.core.windows.net/public-dev/reader_backgrounds/Mystic_Jesus_53.jpg')";
}

</script>

