<template>
  <div class="share-container">
    <button @click="onSetShared" :class="{'shared-button': sharingState, 'unshared-button': !sharingState}">
      {{ sharingState ? 'Un-Share your reading' : 'Share your reading' }}
    </button>
    <div class="social-buttons" v-if="sharingState">
      <button @click="copyLink">
        <i class="fa fa-copy copy-icon"></i>
      </button>
      <s-facebook
          :window-features="windowFeatures"
          :share-options="shareOptionsFacebook"
          :use-native-behavior="useNativeBehavior"
          @popup-close="onClose"
          @popup-open="onOpen"
          @popup-block="onBlock"
          @popup-focus="onFocus"
      >
        <i class="fa-brands fa-facebook facebook-icon"></i>
      </s-facebook>
      <s-twitter
          :window-features="windowFeatures"
          :share-options="shareOptionsTwitter"
          :use-native-behavior="useNativeBehavior"
          @popup-close="onClose"
          @popup-open="onOpen"
          @popup-block="onBlock"
          @popup-focus="onFocus"
      >
        <i class="fa-brands fa-twitter twitter-icon"></i>
      </s-twitter>
      <s-linked-in
          :window-features="windowFeatures"
          :share-options="shareOptionsLinkedIn"
          :use-native-behavior="useNativeBehavior"
          @popup-close="onClose"
          @popup-open="onOpen"
          @popup-block="onBlock"
          @popup-focus="onFocus"
      >
        <i class="fa-brands fa-linkedin linkedin-icon"></i>
      </s-linked-in>
    </div>
  </div>
</template>


<script>
import { SFacebook, STwitter, SLinkedIn } from 'vue-socials'
import { store } from '@/store'
import { toggleShare } from '@/api-service'

export default {
  name: 'SFacebookSharing',
  components: { SFacebook, STwitter, SLinkedIn },
  props: {
    sharing_state: {
      type: Boolean,
      required: true,
    },
    share_id: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = process.env.VUE_APP_DJANGO_SHARE_URL + '/s/' + this.share_id;
    const url_linked_in = process.env.VUE_APP_DJANGO_SHARE_URL + '/li/' + this.share_id;

    return {
      sharingState: this.sharing_state,
      windowFeatures: {},
      shareOptionsFacebook: {
        url: url,
        quote: 'Check out my Mystic AIda reading!',
        hashtag: '#MysticAida, #TarotReading, #AIReadings, #SpiritualAwakening',
      },
      shareOptionsTwitter: {
        url: url,
        text: 'Check out my Mystic AIda reading!',
        hashtags: 'MysticAida,TarotReading,IntuitiveReading,AIReadings,SpiritualAwakening',
      },
      shareOptionsLinkedIn: {
        url: url_linked_in,
      },
      useNativeBehavior: false,
      store: store,
    }
  },
  watch: {
    sharing_state(newVal) {
      this.sharingState = newVal;
    }
  },
  methods: {
    async onSetShared() {
      await toggleShare(!this.sharing_state);
      this.$emit('update:sharing_state', !this.sharingState);
      this.sharingState = !this.sharingState;
    },
    copyLink() {
      const url = process.env.VUE_APP_DJANGO_SHARE_URL + '/s/' + this.share_id;
      navigator.clipboard.writeText(url).then(() => {
        this.$notify({
          text: 'Copy to Clipboard Successful',
          type: 'success',
        });
      });
    },
    onClose() { },
    onOpen() { },
    onBlock() { },
    onFocus() { },
  }
};
</script>


<style scoped>
.share-container {
  display: flex;
  align-items: center;
  margin: 10px 10px 10px 0;
}

.shared-button, .unshared-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.unshared-button {
  background-color: #4CAF50;
}

.social-buttons {
  display: flex;
  align-items: center;
}

.facebook-icon {
  color: #1877F2; /* Natural blue color for Facebook icon */
  font-size: 32px;
  margin-right: 10px;
  margin-left: 10px;
}

.twitter-icon {
  color: #1DA1F2; /* Natural blue color for Twitter icon */
  font-size: 32px;
  margin-right: 10px;
}

.linkedin-icon {
  color: #0A66C2; /* Natural blue color for LinkedIn icon */
  font-size: 32px;
  margin-right: 10px;
}

.email-icon {
  color: #EA4335; /* Natural red color for Email icon */
  font-size: 32px;
  margin-right: 10px;
}

.whatsapp-icon {
  color: #25D366; /* Natural green color for WhatsApp icon */
  font-size: 32px;
  margin-right: 10px;
}

.messenger-icon {
  color: #0084FF; /* Natural blue color for Messenger icon */
  font-size: 32px;
  margin-right: 10px;
}

.copy-icon {
  color: #fff; /* Black color for copy icon */
  font-size: 16px;
  padding:2px 0 2px 0;
  margin: 0 0 0 0;
}
</style>
