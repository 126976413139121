<template>
  <transition name="fade">
    <div v-if="showResponseContainer" class="response-container">
      <div class="x-modal-header" style="margin-top: 40px; margin-right: 5px;">
        <close-icon :on-click="do_a_new_reading"></close-icon>
      </div>

    <div class="share-pane" v-if="is_shared">
  <div class="columns-container">
    <div class="hero-column">
      <h1>{{querent_name}} shared:</h1>
      <h2>{{ userMessage }}</h2>

      <button class="cta-button" v-if="!querentId" @click="showLoginModal">Join/Login</button>
    </div>
    <div class="image-column">
      <template v-if="imageUrls && imageUrls.length > 0">
        <!-- Iterate over imageUrls with v-for -->
        <img v-for="(url, index) in imageUrls" :key="index" :src="url" alt="Tarot Image"
             class="carousel-image">
      </template>
    </div>
  </div>
</div>

      <h2 v-if="!is_shared">{{ userMessage }}</h2>
      <h3 v-if="is_shared">{{ querent_name }} selected these cards:</h3>
      <div class="content-flex-container" style="margin: 0; padding: 0;">
        <div class="response-content">
          <div class="initial-reading" v-show="!show_full_reading">
            <div class="custom-cards-and-descriptions-container">
              <div v-for="(card, index) in selectedCards" :key="card.name" class="card-and-description">
                <!-- Apply opacity to Future and Advice cards -->
                <div>
                  <div style="margin-bottom: 2px">
                    <span v-if="index === 0"><b>Past</b></span>
                    <span v-else-if="index === 1"><b>Present</b></span>
                    <span v-else-if="index === 2"><b>Future</b></span>
                    <span v-else-if="index === 3"><b>Advice</b></span>
                  </div>
                  <div :class="{ 'text-container': true, 'reversed': selectedCards[index].reversed }">
                    <b>{{ card.name }}</b>
                  </div>
                  <!-- Card Image and Description -->
                  <img :src="card.image_path" :alt="card.name"
                    :class="['tarot-card', { 'reversed-card': card.reversed }]" class="card-image-in-description">
                  <p :style="{ opacity: index >= 2 && !show_full_reading ? '0.08' : '1' }">{{ card_readings[`card_${index + 1}`] }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Wrap button container -->
          <div class="button-wrapper">
            <div class="full-reading-button-container" >
              <button v-if="!isLoadingReading && !paid_for_reading" @click="display_full_reading">
                {{ !show_full_reading ? 'See Your Future' : 'See Your Future' }}<br>
                <i v-if="!paid_for_reading && !querent_subscribed" class="fas fa-gem" title="Pay With Emeralds"></i>{{ (!paid_for_reading && !querent_subscribed) ? '25 ' : '' }}
              </button>
            </div>
          </div>
        </div>
        <!-- Free Reading End -->
        <div class="reading-and-carousel-container" v-show="show_full_reading">
          <div class="custom-cards-and-descriptions-container">
            <!-- Card Descriptions with Images -->
            <div v-for="(card, index) in selectedCards" :key="card.name" class="card-and-description">
              <div style="margin-bottom: 10px">
                <span v-if="index === 0" class="text-container"><b>Past</b></span>
                <span v-else-if="index === 1" class="text-container"><b>Present</b></span>
                <span v-else-if="index === 2" class="text-container"><b>Future</b></span>
                <span v-else-if="index === 3" class="text-container"><b>Advice</b></span>
              </div>
              <div :class="{ 'text-container': true, 'reversed': card.reversed }">
                <b>{{ card.name }}</b>
              </div>
              <!-- Card Image -->
              <img :src="card.image_path" :alt="card.name"
                :class="['tarot-card', { 'reversed-card': card.reversed }]" class="card-image-in-description">
              <!-- Card Description -->
              <p style="text-align: left">{{ card_readings[`card_${index + 1}`] }}</p>
            </div>
          </div>
        </div>
        <div v-show="show_full_reading">
          <h2 style="margin-bottom:1px;" v-if="!is_shared">Reading</h2>
          <h3 v-if="is_shared">{{ querent_name }} Reading</h3>
          <div class="primary-reading-container">
            <div class="primary-reading-box">
              <div class="primary-reading" v-html="formatText(primary_reading)"></div>
              <button v-show="!is_shared" @click="show_chat_flyout">
                {{ showHideChatLabel }}
              </button>
              <div>
              <ShareContainer
                  v-if="!is_shared"
                  :share_id="share_id"
                  :sharing_state="sharing_state"/>
              </div>
            </div>
          </div>
        </div>
        <div class="illustration-container" v-show="show_full_reading" v-if="!is_shared">
          <h2>Reading Illustration</h2>
          <div style="flex: 1">
            <div class="image-carousel">
              <!-- Use v-if on a container element -->
              <template v-if="imageUrls && imageUrls.length > 0">
                <!-- Iterate over imageUrls with v-for -->
                <img v-for="(url, index) in imageUrls" :key="index" :src="url" alt="Tarot Image"
                     class="carousel-image">
              </template>
              <!-- Fallback content if imageUrls is empty -->
              <span class="primary-reading-image" v-else>
                <!-- You can either display a default image -->
                <img src="@/assets/crystal-loading.gif" alt="crystal loading" class="carousel-image">
              </span>
            </div>
          </div>
          <div v-if="!imageUrls || imageUrls.length === 0" class="loading-message-container">
            <span class="loading-message" >
              Generating Image: {{ loadingMessage }}
            </span>
          </div>
         </div>
        <div class="reading-button-container">
          <button v-show="show_full_reading" @click="do_a_new_reading">
            <i class="fa-solid fa-plus"></i>New Reading
          </button>
        </div>
        <button class="cta-button" v-if="!querentId" @click="showLoginModal">Join/Login</button>
      </div>
    </div>
  </transition>

</template>

<script>
import ShareContainer from '../ShareContainer.vue';
import CloseIcon from '@/components/ui/CloseIcon.vue';


export default {
  components: {
    ShareContainer,
    CloseIcon,
  },
  props: {
    showResponseContainer: Boolean,
    show_full_reading: Boolean,
    showRevealedCards: Boolean,
    userMessage: String,
    selectedCards: Array,
    card_readings: Object,
    primary_reading: String,
    messages: Array,
    follow_up_user_message: String,
    imageUrls: Array,
    loadingMessage: String,
    store: Object,
    isLoadingReading: Boolean,
    paid_for_reading: Boolean,
    showFlyout: Boolean,
    show_chat_button:Boolean,
    is_shared:Boolean,
    sharing_state:Boolean,
    share_id:String,
    querent_name:String,
    querent_subscribed:Boolean,
    showLogin: Boolean,
    querentId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    showHideChatLabel() {
      return this.showFlyout ? 'Hide Chat Window' : 'Chat with Mystic AIda';
    },
    pastCardDescription() {
      return this.card_readings && this.selectedCards.length > 0 ? this.card_readings['card_1'] : '';
    },
    presentCardDescription() {
      return this.card_readings && this.selectedCards.length > 1 ? this.card_readings['card_2'] : '';
    }
  },
    methods: {
    showLoginModal() {
      this.$emit('update:showLogin', true);
    }
  },
  emits: ['do_a_new_reading', 'display_full_reading','show_chat_flyout', 'continueChat', 'clearFollowUpUserMessage',
  'show_login_modal'],
  setup(props, { emit }) {
    const formatText = (text) => {
      return text.replace(/\n/g, '<br>');
    };

    const do_a_new_reading = () => {
      emit('do_a_new_reading');
    };

    const display_full_reading = () => {
      emit('display_full_reading');
    };

    const show_chat_flyout = () => {
      emit('show_chat_flyout');
    };

    return {
      formatText,
      do_a_new_reading,
      display_full_reading,
      show_chat_flyout,
    };
  }
};
</script>

<style scoped>
h3 {
  font-size: 1em;
  font-weight: bold;
  color: #663399;
  text-align: center;
  margin: 10px 10px 10px 10px;
  padding: 10px 20px;
  border: 2px solid #663399;
  border-radius: 10px;
  background-color: #f3e8ff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tarot-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: bold;
  color: #663399;
  box-sizing: border-box;
  width: 100%;

}

.text-container.reversed {
  transform: rotate(180deg);
}

.revealed-cards-container .card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal textarea {
  width: 80%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

.x-modal-header {
  display: flex;
  justify-content: flex-end;
}

.response-container {
  width: 100%;
  max-width: 980px;
  height: auto;
  max-height: 90vh;
  background: #d5d3d3;
  box-sizing: border-box;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 999;
}

.content-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.response-content {
  flex: 1;
  overflow: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  margin-bottom: 10px;
  margin-top: 0;
}

.full-reading-button-container {
  align-items: center;
  text-align: center;
}

.primary-reading {
  margin: 10px;
}

.primary-reading-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.primary-reading-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 0;
  margin-bottom: 50px;
}

.primary-reading-image {
  margin: 32px;
  text-align: right;
}

.image-carousel {
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.carousel-image {
  max-width: 94%;
  height: auto;
  margin-bottom: 10px;
}

.loading-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.loading-message {
  font-size: 1.2em;
  color: #663399;
}

button {
  background-color: #663399;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
}

button i {
  margin-right: 5px;
  margin-left: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
}

button {
  background-color: #663399;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #7c51a8;
}

textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  font-size: 16px;
  resize: vertical;
}

.follow-up-container textarea {
  flex-grow: 1;
  margin-right: 0;
}

.follow-up-container button {
  flex-shrink: 0;
}

.reading-button-container {
  justify-content: center;
  display: flex;
  text-align: left;
  margin-bottom: 25px;
}

.product-info h3 {
  margin: 0 0 10px 0;
  color: #333;
  text-align: left;
}

.custom-cards-and-descriptions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.card-and-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  width: 180px;
}

.card-and-description .tarot-card,
.card-and-description .card-back {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-image-in-description {
  margin-bottom: 10px;
}

.reading-and-carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.button-wrapper {
  position: absolute;
  bottom: 100px;
  left: 70%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
}

.full-reading-button-container button {
  background-color: #663399;
  color: white;
  padding: 40px 50px 40px 50px;
  font-size: 1.6em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.share-pane {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.columns-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.hero-column {
  flex: 1;
  padding: 20px;
  background-color: #663399;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.hero-column h1 {
  margin-top: 0;
}

.hero-column p {
  margin: 10px 0;
}

.hero-column .cta-button {
  background-color: #9a71bd;
  color: white;
  border: none;
  padding: 15px 15px;
  margin: 40px 0 0 0;
  border-radius: 7px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.hero-column .cta-button:hover {
  background-color: #552288;
}

.image-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-column img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/****************************** Viewport 600 *************************/
@media screen and (max-width: 600px) {
  .columns-container {
    flex-direction: column;
  }

  .hero-column {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .image-column {
    margin-top: 10px;
  }

  .image-column img {
    width: 100%;
    height: auto;
  }

  .button-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    z-index: 10;
  }

  .full-reading-button-container button {
    background-color: #663399;
    color: white;
    padding: 50px 50px 50px 50px;
    font-size: 1.6em;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .two-column-container {
    flex-direction: column;
  }

  .hero-column,
  .image-column {
    width: 100%;
  }

  .hero-column {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .image-column img {
    width: 100%;
  }
}

/****************************** Viewport 1024 *************************/
@media screen and (max-width: 1024px) {
  .modal textarea {
    width: 90%;
    height: 90px;
    margin-top: 5px;
    margin-bottom: 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1em;
  }

  .revealed-cards-container .card {
    flex: 1 1 25%;
    position: static;
    max-width: 100px;
    box-sizing: border-box;
  }

  .response-container {
    left: 0;
    top: auto;
    transform: translate(0, 0);
    width: 100%;
    max-width: none;
    height: auto;
    overflow-x: auto;
    overflow-y: auto;
    justify-content: center;
    padding: 0 0 0 0;
  }

  .content-flex-container {
    flex-direction: column;
    margin-bottom: 10px;
    overflow-y: visible;
    align-items: center;
  }

  .carousel-image {
    top: 20px;
  }

  .response-content {
    flex: 1;
    overflow: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;
  }

  .button-wrapper {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    z-index: 10;
  }

  .full-reading-button-container button {
    background-color: #663399;
    color: white;
    padding: 25px 125px 25px 125px;
    font-size: 1.6em;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .full-reading-button-container button i {
    font-size: 1.2em;
    margin-right: 10px;
  }

  .menu-modal .modal-content {
    width: 100%;
    min-height: 500px;
  }

  .custom-cards-and-descriptions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px;
  }

  .card-and-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    width: 45%;
  }

  .card-image-in-description {
    margin-bottom: 0;
  }
}
</style>



