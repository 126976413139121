// store.js
import { reactive } from 'vue'

export const store = reactive({
  querent_emerald_balance: 0,
  querent_id: '',
  querent_name: '',
  querent_email: '',
  querent_subscription_expiration: '',
  session_id: '',
  reading_session_id: '',
  reading_sessions: [],
  is_loading: false,
  login_current_tab: 'login',
})

export const static_store = {
  apiUrl: process.env.VUE_APP_DJANGO_API_URL,
  stripe_public_key: process.env.VUE_APP_STRIPE_PUBLIC_KEY
}