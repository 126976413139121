<template>
  <div class="flyout-panel" v-if="showFlyout">
    <close-icon class="close-icon-right" :on-click="show_chat_flyout" ></close-icon>
    <h3 style="margin-bottom:0;margin-top: 35px">Conversation Journal</h3>
    <div class="chat-box-container">
      <!-- Ask Follow Up Question -->
      <div class="chat-container" ref="chat_container_ref">
        <div v-for="(message, index) in messages" :key="index"
          :class="['message-box', message.type === 'user' ? 'user-message' : 'reader-response']">
          <p>{{ message.text }}</p>
        </div>
      </div>
      <div v-if="!isFetchingResponse" class="follow-up-container">
        <textarea v-model="internal_follow_up_user_message" placeholder="Respond"></textarea>
        <button @click="sendChat"><i class="fa-solid fa-paper-plane"></i></button>
      </div>
    </div>
    <button v-show="showFlyout" @click="show_chat_flyout">
      Close Chat
    </button>
  </div>
</template>

<script>
import CloseIcon from "@/components/ui/CloseIcon.vue";

export default {
  components: {CloseIcon},
  props: {
    showFlyout: Boolean,
    messages: Array,
    isFetchingResponse: Boolean,
    local_follow_up_user_message: String
  },
  data() {
    return {
      internal_follow_up_user_message: this.local_follow_up_user_message
    };
  },
  watch: {
    local_follow_up_user_message(newVal) {
      this.internal_follow_up_user_message = newVal;
    },
    messages: {
      handler() {
        this.scrollChatBox();
      },
      deep: true
    }
  },
  mounted() {
    this.scrollChatBox();
  },
  methods: {
    sendChat() {
      this.$emit('update:local_follow_up_user_message', this.internal_follow_up_user_message);
      this.$emit('continueChat');
      this.internal_follow_up_user_message = '';
      this.scrollChatBox();
    },
    scrollChatBox() {
      this.$nextTick(() => {
        const textarea = this.$refs.chat_container_ref;
        if (textarea) {
          textarea.scrollTop = textarea.scrollHeight;
        }
      });
    }
  },
  emits: ['update:local_follow_up_user_message', 'continueChat', 'show_chat_flyout'],
  setup(props, {emit}) {
    const show_chat_flyout = () => {
      emit('show_chat_flyout');
    };
    return {
      show_chat_flyout,
    };
  }
};
</script>

<style scoped>
.chat-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px 50px 20px;
}

.chat-container {
  margin-top: 0;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 10px;
  max-height: 50vh;
  min-height: 100px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.flyout-panel {
  position: fixed;
  top: 48px;
  right: 0;
  height: 85.8%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  padding: 20px;
  width: 350px;
  min-width: 350px;
  transition: width 0.3s ease-in-out;

}

@media (min-width: 768px) {
  .flyout-panel {
    width: 40%;
  }
}

@media (min-width: 1024px) {
  .flyout-panel {
    width: 30%;
  }
}

@media (min-width: 1440px) {
  .flyout-panel {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .chat-box-container {
    margin: 0 30px 50px 30px; /* Increased margins for easier scrolling */
  }
  .flyout-panel {
    width: 90%;
    min-width: unset;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .chat-box-container {
    margin: 0 40px 50px 40px; /* Further increased margins for easier scrolling */
  }
  .flyout-panel {
    width: 95%;
    min-width: unset;
    padding: 5px;
  }
}

.close-icon-right {
  position: absolute;
  top: 20px; /* Increased top margin to avoid overlap */
  right: 10px;
  cursor: pointer;
  z-index: 1001; /* Ensure it is above other elements */
  font-size: 24px; /* Increase size if necessary */
}

.follow-up-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.follow-up-container textarea {
  flex: 1;
  resize: none;
  border: none;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
}

.follow-up-container button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
}

.follow-up-container button i {
  font-size: 18px;
}
</style>






