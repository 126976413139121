<template>
  <div>
    <div style="margin: 32px;" v-if="loginMethods.includes('password')">
      <div class="tabs" v-if="forgotPasswordMode === false">
        <button :class="{ 'active-tab': currentTab === 'login' }" @click="updateCurrentTab('login')">Login</button>
        <button :class="{ 'active-tab': currentTab === 'register' }" @click="updateCurrentTab('register')">Register
        </button>
      </div>

      <div style="padding: 32px;" v-if="forgotPasswordMode === true">
        Request a Password Reset Email
      </div>

      <div v-if="currentTab === 'login' && forgotPasswordMode === false">
        <form @submit.prevent="login">
          <input class="input-field" type="email" v-model="loginEmail" placeholder="Email" required>
          <input class="input-field" type="password" v-model="loginPassword" placeholder="Password" required>
          <button class="submit-button" @click.prevent="login()">Login</button>
          <div style="margin: 16px;">
            <span style="cursor: pointer" @click="updateForgotPasswordMode(true)">Forgot password?</span>
          </div>
        </form>
      </div>

      <div v-if="currentTab === 'login' && forgotPasswordMode === true">
        <form @submit.prevent="forgotPassword">
          <input class="input-field" type="email" v-model="resetPasswordEmail" placeholder="Email" required>
          <button class="submit-button" @click.prevent="requestResetPassword()">Reset Password</button>
          <div style="margin: 16px;">
            <span style="cursor: pointer" @click="updateForgotPasswordMode(false)">Back to login</span>
          </div>
        </form>
      </div>

      <div v-if="currentTab === 'register'">
        <form @submit.prevent="register">
          <input class="input-field" type="email" v-model="registerEmail" placeholder="Email" required>
          <input class="input-field" type="password" v-model="registerPassword" placeholder="Password" required>
          <button class="submit-button" @click.prevent="register()">Register</button>
        </form>
      </div>

    </div>
    <button style="margin: 16px;" v-if="loginMethods.includes('google')" @click="loginWithGoogle()">
      <i class="fa-brands fa-google"></i>
    </button>
    <button style="margin: 16px;" v-if="loginMethods.includes('facebook')" @click="test()">
      <i class="fa-brands fa-facebook"></i>
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import {store, static_store} from '@/store';
import {getQuerent, setQuerentOnReading} from '@/api-service'
import {parseAxiosError} from '@/utils/general-utils';

axios.defaults.withCredentials = true;

export default {
  props: {
    loginMethods: {
      type: Array,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    forgotPasswordMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loginEmail: '',
      loginPassword: '',
      registerEmail: '',
      registerPassword: '',
      store: store,
      resetPasswordEmail: '',
      static_store: static_store,
    };
  },
  methods: {
    updateCurrentTab(tab) {
      console.log('CURRENT TAB:', tab); // Debug log
      this.$emit('updateTab', tab);
    },
    updateForgotPasswordMode(mode) {
      this.$emit('updateForgotPasswordMode', mode);
    },
    async requestResetPassword() {
      if (!this.is_valid_email(this.resetPasswordEmail)) {
        console.log("invalid email")
        this.$notify({
          text: 'Invalid Email',
          type: 'error',
        })
        return;
      }
      this.store.is_loading = true;
      try {
        await this.get_csrf_token()

        const response = await axios.post(this.static_store.apiUrl + '/auth/request-password-reset/', {
          email: this.resetPasswordEmail,
        }, {
          headers: {
            'X-CSRFToken': localStorage.getItem('csrfToken')
          }
        });
        if (response.status === 200) {
          this.$notify({
            text: 'Password reset email sent',
            type: 'success',
          })
          this.updateForgotPasswordMode(false);
        } else {
          this.$notify({
            text: 'Something went wrong',
            type: 'error',
          })
        }
        console.log(response)
      } catch (e) {
        console.log(e)
      } finally {
        this.store.is_loading = false;
      }
    },
    test() {
      console.log('test')
    },
    loginWithGoogle() {
      const array = new Uint32Array(10);
      window.crypto.getRandomValues(array);
      const stateKey = Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');

      localStorage.setItem('loadSession', true);
      window.location = `https://accounts.google.com/o/oauth2/v2/auth?` +
          `response_type=code&` +
          `client_id=${encodeURIComponent(process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID)}&` + // Replace with your client ID
          `redirect_uri=${encodeURIComponent(window.location.origin)}&` + // Replace with your redirect URI
          `scope=${encodeURIComponent('https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email')}&` +
          `access_type=offline&` +
          `state=${encodeURIComponent(stateKey)}`; // Replace with your state string
    },
    is_valid_password(password) {
      // Check if the password is at least 6 characters long and contains
      // at least one capital letter or a special character.
      return password.length >= 6 && (/[A-Z]/.test(password) || /[!@#$%^&*(),.?":{}|<>]/.test(password));
    },
    is_valid_email(email) {
      // Check if the string is a valid email format.
      const email_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return email_pattern.test(email);
    },
    async get_csrf_token() {
      const response = await axios.get(`${this.static_store.apiUrl}/csrf/`);
      console.log("response", response)
      if (response.data.csrfToken) {
        console.log("setting csrfToken", response.data.csrfToken);
        localStorage.setItem('csrfToken', response.data.csrfToken);
        document.cookie = `csrftoken=${response.data.csrfToken};path=/`;
      }
    },
    async login() {
      try {
        this.store.is_loading = true;
        await this.get_csrf_token()
        const response = await axios.post(this.static_store.apiUrl + '/dj-rest-auth/login/', {
          username: this.loginEmail,
          password: this.loginPassword
        }, {
          headers: {
            'X-CSRFToken': localStorage.getItem('csrfToken')
          }
        });
        if (response?.data?.key) {
          console.log("login response", response)
          localStorage.setItem('authToken', response.data.key);  // Store the token
          localStorage.setItem('querent_id', response.data.querent_id); // Store the token
          this.store.querent_id = response.data.querent_id
          this.store.querent_emerald_balance = response.data.querent_emerald_balance
          await setQuerentOnReading(response.data.querent_id, this.store.reading_session_id)
          this.$notify({
            text: 'Login Successful',
            type: 'success'
          })
          this.$emit('loginSuccess', {
            querent_id: response.data.querent_id,
            querent_emerald_balance: response.data.querent_emerald_balance,
            showLogin: false,
            showQuerentQuestionModal: true,
            progress_indicator: 'initial'
          });
          await getQuerent(response.data.querent_id);
        } else {
          console.log("login response", response)
          this.$notify({
            text: 'Invalid Credentials',
            type: 'error',
          })
        }
      } catch (error) {

        // Handle login error
        console.log("login response error", error)
        this.$notify({
          title: 'Error',
          text: parseAxiosError(error),
          type: 'error'
        })
      } finally {
        this.store.is_loading = false;
      }
    },
    async register() {

      if (this.store.is_loading) {
        return;
      }

      if (!this.is_valid_email(this.registerEmail)) {
        console.log("invalid email")
        this.$notify({
          text: 'Invalid Email',
          type: 'error',
        })
        return;
      }
      if (!this.is_valid_password(this.registerPassword)) {
        console.log("invalid password")
        this.$notify({
          text: 'Invalid Password',
          type: 'error',
        })
        return;
      }
      this.store.is_loading = true;
      //TODO password and email requirements
      console.log("csrfToken", localStorage.getItem('csrfToken'))
      try {
        const registerResponse = await axios.post(this.static_store.apiUrl + '/dj-rest-auth/registration/', {
          email: this.registerEmail,
          username: this.registerEmail,
          password1: this.registerPassword,
          password2: this.registerPassword
        }, {
          headers: {
            'X-CSRFToken': localStorage.getItem('csrfToken')
          }
        });
        console.log(registerResponse)
        console.log("response status code", registerResponse.status)
        if (registerResponse.status === 201 || registerResponse.status === 204) {
          console.log("was a 204", registerResponse.status)
          this.loginEmail = this.registerEmail
          this.loginPassword = this.registerPassword
          await this.get_csrf_token()
          await this.login()
          // TODO user is registered, handle login of this user
          // Handle successful registration
          // e.g., redirect to login page or show a success message
        } else {
          this.$notify({
            text: 'Invalid credentials',
            type: 'error',
          })
        }

        // Handle successful registration
      } catch (error) {
        console.log("error", error)
        let emessage = parseAxiosError(error)
        this.$notify({
          text: emessage,
          type: 'error',
        })
      } finally {
        this.store.is_loading = false;
      }
    },
    loginWithFacebook() {
      window.location = 'FACEBOOK_OAUTH_URL'; // Replace with your Facebook OAuth URL
    },
    authenticate(provider) {
      this.$auth.authenticate(provider).then(response => {
        // Handle authentication
        //console.log(VueAuthenticate.name)
        this.verifyTokenWithBackend(response);
      }).catch(error => {
        console.error('Authentication error: ', error);
      });
    },
    test_auth() {
      const token = localStorage.getItem('authToken')
      console.log(token)
      axios.post('http://localhost:8000/reading/test/', {}, { // Data object is empty here
        headers: {
          Authorization: `Token ${token}`
        }
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.error('Error:', error);
      });
    },

    async verifyTokenWithBackend(response) {
      try {
        const backendResponse = await this.$http.post('http://localhost:8000/socialaccounts/', {
          token: response.access_token,
          backend: response.provider  // 'google' or 'facebook'
        });
        console.log(backendResponse)
        // Handle backend response
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style>
.tabs button {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  border-radius: 0;
  color: #000;
}

.tabs button:hover {
  background-color: #eee;
}

.active-tab {
  border-bottom: 2px solid #663399;
  background-color: #eee !important;
}

.input-field {
  width: 100%;
  display: block;
  margin: 16px 0;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 4px;
}

@media screen and (max-width: 475px) {
  /* .input-field {
      width: 200px;
      max-width: 200px;
  } */
}
</style>
